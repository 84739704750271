@charset "UTF-8";

$theme-colors: (
  primary: #1e5999,
);

$input-placeholder-color: #b7b7b7;

@import '~bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons';

fieldset {
  margin-bottom: 30px;
}

// Hide arrows in input number
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.sideNavColumn {
  top: 0;
  left: -5px;
}

// Prevent opacity - 0 on input after select value with react-select Async component
input[id^='react-select-'] {
  opacity: 1 !important;
}

.pricing-input input[type='text'] {
  text-align: right;
}

// Prevent text decoration on all 4 Media category buttons
.media-category:hover {
  text-decoration: none;
}

.custom-time-input {
  label {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 1rem;

    &:not(:first-of-type) {
      margin-left: 1rem;
    }
  }
}

// Text area min height
.custom-textarea {
  min-height: 130px;
}

.react-datepicker-popper {
  z-index: 15 !important;
}

.mw-small-input {
  max-width: 170px;
  width: 100%;
}

.no-link-styles {
  color: inherit;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.pos-bottom-left {
  bottom: 0;
  left: 0;
  z-index: 10;
}
